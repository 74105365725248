import { useEffect, useState } from "react";
import { AiFillEdit, AiFillEye } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../api";
import { CENTER_REPORT_LIST } from "../../api/endpoints";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const { centerId } = useParams();
  const fetchReports = async () => {
    try {
      const result = await axiosInstance.get(
        CENTER_REPORT_LIST.replace(":id", centerId)
      );
      const data = result.data;
      setReports(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <div className="p-5 flex flex-col space-y-4 h-full">
      <input
        className="w-full pl-4 pr-6 py-3 rounded-md bg-gray-100 text-primary"
        placeholder="Search"
        type="text"
      />
      <div className="w-full flex flex-row px-4 py-2 bg-gray-300 text-center mb-1">
        <p className="flex-1">Report No.</p>
        <p className="flex-1">Quarter</p>
        <p className="flex-1">Year</p>
        <p className="flex-1">Submission Date</p>
        <p className="flex-1">Actions</p>
      </div>
      <div className="overflow-y-scroll flex-1">
        {reports.map((report) => (
          <div
            key={report?.id}
            className={`w-full flex flex-row px-4 py-2 text-center mb-1 ${
              report.status === "COMPLETED" ? "bg-green-100" : "bg-gray-100"
            }`}
          >
            <p className="flex-1">
              REP{(report?.id).toString().padStart(5, "0")}
            </p>
            <p className="flex-1">{report?.quarter}</p>
            <p className="flex-1">{report?.academicYear}</p>
            <p className="flex-1">
              {report?.status === "PENDING"
                ? "Yet to Submit"
                : new Date(report?.submittedAt).toDateString()}
            </p>
            <div className="flex-1 flex flex-row justify-center items-center space-x-2">
              <Link
                to={`/center/${centerId}/reports/${report?.id}`}
                className="hover:text-primary"
              >
                <AiFillEye className="text-2xl" />
              </Link>
              {report?.status === "PENDING" && (
                <Link
                  to={`/center/${centerId}/reports/${report?.id}/edit`}
                  className="hover:text-primary"
                >
                  <AiFillEdit className="text-2xl" />
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reports;
