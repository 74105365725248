import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const OuterWrapper = () => {
  return (
    <div className="h-screen overflow-y-auto flex flex-col font-pop">
      <Outlet />
    </div>
  );
};

export default OuterWrapper;
