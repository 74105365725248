import { Outlet, useNavigate } from "react-router-dom";
import Logo from "../assests/logo.png";
import { useAuth } from "../context/AuthContext";

const Navbar = () => {
  const navigate = useNavigate();
  const { loggedInUser } = useAuth();

  return (
    <>
      <div className="flex flex-row items-center space-x-4 p-5">
        <img
          className="aspect-auto h-10 md:h-14"
          src={Logo}
          alt="PSG College of Technology"
        />
        <div className="flex flex-col justify-center text-black font-ws font-semibold text-sm md:text-xl">
          <p>PSG College of Technology</p>
          <p>Coimbatore</p>
        </div>
        <div className="flex-1"></div>
        {loggedInUser && (
          <>
            {loggedInUser.centers && loggedInUser.centers.length > 0 && (
              <select
                onChange={(e) => {
                  const centerId = e.target.value;
                  if (centerId) {
                    navigate(`center/${centerId}/home`, { replace: true });
                  }
                }}
                className="mb-1 px-4 py-3 rounded-md bg-gray-100"
              >
                <option disabled selected value>
                  Select a Center
                </option>
                {loggedInUser?.centers?.map((userCenter, index) => {
                  if (userCenter) {
                    return (
                      <option
                        key={index}
                        className="hover:bg-gray-200 bg-gray-100"
                        value={userCenter.center?.id}
                      >
                        {userCenter.center?.name}
                      </option>
                    );
                  }
                  return <></>;
                })}
              </select>
            )}
          </>
        )}
      </div>
      <Outlet />
    </>
  );
};

export default Navbar;
