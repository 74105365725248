import { useEffect, useState } from "react";
import { BiTimeFive } from "react-icons/bi";
import {
  AiFillCaretRight,
  AiFillCheckCircle,
  AiFillWarning,
} from "react-icons/ai";
import { BsHourglassSplit } from "react-icons/bs";
import axiosInstance from "../../api";
import { CENTER_REPORT_CHECK } from "../../api/endpoints";
import { useNavigate, useParams } from "react-router-dom";

const Submitted = () => {
  return (
    <div className="text-4xl flex flex-col items-center space-y-4 text-primary">
      <p>You have submitted the report for this quarter.</p>
      <AiFillCheckCircle className="text-7xl" />
    </div>
  );
};

const DaysLeft = ({ days, link }) => {
  const navigate = useNavigate();
  return (
    <div className="text-4xl flex flex-col items-center space-y-4 text-primary">
      <p>{days} day(s) left to submit the form.</p>
      <BsHourglassSplit className="text-7xl" />
      <button
        onClick={(e) => {
          navigate(link, { replace: true });
        }}
        className="bg-primary text-white text-xl px-4 py-3 rounded-lg"
      >
        Update and Submit Now{" "}
        <AiFillCaretRight className="inline text-2xl ml-2" />
      </button>
    </div>
  );
};

const DeadlineExceeded = () => {
  return (
    <div className="text-4xl flex flex-col items-center space-y-4 text-red-600">
      <p>Deadline exceeded.</p>
      <AiFillWarning className="text-7xl" />
      <button className="bg-red-600 text-white text-xl px-4 py-3 rounded-lg">
        Request Extension <BiTimeFive className="inline text-2xl ml-2" />
      </button>
    </div>
  );
};

const CenterHome = () => {
  const [status, setStatus] = useState("PENDING");
  const [deadlineDaysLeft, setDeadlineDaysLeft] = useState(0);
  const [reportLink, setReportLink] = useState("");

  const { centerId } = useParams();

  const reportCheck = async () => {
    try {
      const result = await axiosInstance.get(
        CENTER_REPORT_CHECK.replace(":id", centerId)
      );
      const data = result.data;
      const report = data?.report;
      if (report && report.status !== "COMPLETED") {
        const today = new Date();
        const deadline = new Date(report.deadline);
        if (today.getTime() > deadline.getTime()) {
          setStatus("DEADLINE");
        } else {
          setStatus("PENDING");
          const diffInTime = deadline.getTime() - today.getTime();
          const diffInDays = diffInTime / (1000 * 3600 * 24);
          setDeadlineDaysLeft(Math.round(diffInDays));
          setReportLink(`/center/${centerId}/reports/${report.id}/edit`);
        }
      } else {
        setStatus("COMPLETED");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    reportCheck();
  }, []);

  return (
    <div className="grid place-items-center h-full">
      {status === "DEADLINE" && <DeadlineExceeded />}
      {status === "PENDING" && (
        <DaysLeft days={deadlineDaysLeft} link={reportLink} />
      )}
      {status === "COMPLETED" && <Submitted />}
    </div>
  );
};

export default CenterHome;
