import { useEffect, useRef } from "react";

export const ScrollIntoView = ({ children, condition, className }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (condition) {
      ref.current?.scrollIntoView();
    }
  }, [condition]);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};

export default ScrollIntoView;
