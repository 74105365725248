import { useForm } from "react-hook-form";
import HeaderImage from "../assests/headerImage.png";
import { AiFillRightCircle } from "react-icons/ai";
import axiosInstance from "../api";
import { AUTH_FORGOT_PASSWORD } from "../api/endpoints";
import { toast } from "react-hot-toast";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (data) => {
    try {
      const result = await axiosInstance.post(AUTH_FORGOT_PASSWORD, data);
      toast.success("Kindly check your email to proceed");
    } catch (err) {
      toast.success("Kindly check your email to proceed");
      const response = err?.response;
      const data = response?.data;
      if (data) {
        for (const key in data) {
          if (key === "_errors") {
            if (data[key].length > 0) {
              setError("root", {
                type: response.status,
                message: data[key].join("\n"),
              });
            }
          } else {
            if (data[key]["_errors"].length > 0) {
              setError(key, {
                type: "server",
                message: data[key]["_errors"].join("\n"),
              });
            }
          }
        }
      }
    }
  };

  return (
    <div className="flex flex-row flex-1 px-5">
      <div className="flex-1 flex flex-row justify-center items-center">
        <div className="text-primary ">
          <div className="text-center mb-16">
            <h1 className="font-bold text-5xl md:text-7xl mb-4 font-ws">
              Centre of Excellence
            </h1>
            <h4 className="text-2xl md:text-4xl font-ws">Forgot Password</h4>
          </div>
          <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="w-full mb-16">
              <div className="mb-8">
                <p className="text-xl md:text-2xl font-bold mb-4 ml-2">
                  Email Address
                </p>
                <input
                  className={`w-full mb-1 px-4 py-3 rounded-md bg-gray-100 ${
                    errors.email
                      ? "text-red-400 outline outline-2 outline-red-400"
                      : "text-primary"
                  }`}
                  placeholder="someone@psgtech.ac.in"
                  type="email"
                  {...register("email", { required: true })}
                />
                {errors?.email && (
                  <p className="text-red-400">{errors?.email?.message}</p>
                )}
              </div>
              {errors?.root && (
                <p className="text-red-400">{errors?.root?.message}</p>
              )}
              <div className="text-center">
                <input
                  type="submit"
                  className="px-4 text-lg py-2 bg-primary text-white rounded-lg cursor-pointer"
                  value="Submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="flex-1 hidden lg:block">
        <img className="" src={HeaderImage} alt="Header Image" />
      </div>
    </div>
  );
};

export default ForgotPassword;
