import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const RequireAuth = ({ allowedRoles }) => {
  const { loggedInUser } = useAuth();
  const location = useLocation();

  if (loggedInUser) {
    if (allowedRoles.includes(loggedInUser?.role)) {
      return <Outlet />;
    } else {
      return <Navigate to={"/center"} replace />;
    }
  } else {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }
};

export default RequireAuth;
