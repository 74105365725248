import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../api";
import { AUTH_LOGIN, USER_ME } from "../api/endpoints";

export const AUTH_LOCAL_STORAGE = "COE_TOKEN";

const authContext = createContext({});

const defaultRoleRoute = {
  ADMIN: "/admin",
  USER: "/center",
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loggedInUser, setLoggedInUser] = useState(null);

  const login = async (body) => {
    try {
      const result = await axiosInstance.post(AUTH_LOGIN, body);
      const data = result.data;

      if (data) {
        localStorage.setItem(AUTH_LOCAL_STORAGE, data.token);
        delete data.token;
        setLoggedInUser(data);
        navigate(
          location.state?.from?.pathname ||
            defaultRoleRoute[data?.role] ||
            "/center",
          {
            replace: true,
          }
        );
      } else {
        throw new Error("Unable to login");
      }
    } catch (err) {
      localStorage.setItem(AUTH_LOCAL_STORAGE, null);
      setLoggedInUser(null);
      throw err;
    }
  };

  const fetchMe = async () => {
    try {
      if (!loggedInUser) {
        const result = await axiosInstance.get(USER_ME);
        const data = result.data;
        if (data) {
          setLoggedInUser(data);
          navigate(
            location.state?.from?.pathname ||
              defaultRoleRoute[data?.role] ||
              "/center",
            {
              replace: true,
            }
          );
        } else {
          throw new Error("Unable to login");
        }
      }
    } catch (err) {
      localStorage.removeItem(AUTH_LOCAL_STORAGE);
      setLoggedInUser(null);
    }
  };

  const logout = () => {
    localStorage.removeItem(AUTH_LOCAL_STORAGE);
    setLoggedInUser(null);
    navigate("/login", {
      replace: true,
    });
  };

  return (
    <authContext.Provider value={{ loggedInUser, login, logout, fetchMe }}>
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => useContext(authContext);

export default AuthProvider;
