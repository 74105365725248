import { useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import ReactQuill from "react-quill";

const RichTextEditor = ({ name, isShow }) => {
  const { control, errors } = useFormContext();

  const {
    field: { value, onChange },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
  });

  return (
    <ReactQuill
      theme="snow"
      readOnly={isShow}
      className={`${
        errors?.otherFeatures && "outline outline-2 outline-red-400"
      }`}
      modules={{ toolbar: !isShow }}
      value={value}
      onChange={onChange}
    />
  );
};

export default RichTextEditor;
