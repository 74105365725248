import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { AiFillHome, AiOutlineUser } from "react-icons/ai";
import { RiFileEditFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";

const Sidebar = () => {
  const location = useLocation();
  const { loggedInUser } = useAuth();
  const [page, setPage] = useState("home");
  const { centerId } = useParams();

  useEffect(() => {
    if (location.pathname.indexOf("home") !== -1) {
      setPage("home");
    } else if (location.pathname.indexOf("reports") !== -1) {
      setPage("reports");
    }
  }, [location.pathname]);

  return (
    <div className="flex flex-row flex-1 min-h-0">
      <div className="h-full p-5 w-60 flex flex-col space-y-4">
        <Link
          to={`/center/${centerId}/home`}
          className={`flex flex-row items-center w-full px-8 py-2 space-x-4 text-xl rounded-md hover:bg-gray-200 cursor-pointer ${
            page === "home" && "bg-primary/40 hover:bg-primary/50"
          }`}
        >
          <AiFillHome />
          <p>Home</p>
        </Link>
        <Link
          to={`/center/${centerId}/reports`}
          className={`flex flex-row items-center w-full px-8 py-2 space-x-4 text-xl rounded-md hover:bg-gray-200 cursor-pointer ${
            page === "reports" && "bg-primary/40 hover:bg-primary/50"
          }`}
        >
          <RiFileEditFill />
          <p>Reports</p>
        </Link>
        <div className="flex-1"></div>
        <div className="flex flex-row items-center w-full px-8 py-2 space-x-4 rounded-md hover:bg-gray-200">
          <AiOutlineUser className="text-4xl" />
          <p className="break-all text-sm">{loggedInUser?.email}</p>
        </div>
        <Link
          to="/logout"
          className="flex flex-row items-center w-full px-8 py-2 space-x-4 text-xl rounded-md hover:bg-gray-200 cursor-pointer"
        >
          <RiFileEditFill />
          <p>Logout</p>
        </Link>
      </div>
      <div className="h-full flex-1 p-2">
        <div className="h-full rounded-3xl shadow-2xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
