import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { AiFillDelete } from "react-icons/ai";
import { GrAdd } from "react-icons/gr";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formatDate } from "../../utils";
import RichTextEditor from "../RichTextEditor";

export const CentreDetails = ({ className, isShow }) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const {
    fields: hardwareList,
    append: hardwareListAppend,
    remove: hardwareListRemove,
  } = useFieldArray({ name: "hardwareList" });

  const {
    fields: softwareList,
    append: softwareListAppend,
    remove: softwareListRemove,
  } = useFieldArray({ name: "softwareList" });

  const {
    fields: technicalStaffDetails,
    append: technicalStaffDetailsAppend,
    remove: technicalStaffDetailsRemove,
  } = useFieldArray({ name: "technicalStaffDetails" });

  const { fields: studentSessions } = useFieldArray({
    name: "studentSessions",
  });

  const weekDays = ["MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const {
    fields: researchActivities,
    append: researchActivitiesAppend,
    remove: researchActivitiesRemove,
  } = useFieldArray({ name: "researchActivities" });

  return (
    <div className={className}>
      <div className="flex flex-row space-x-5 mb-8">
        <div className="flex-1">
          <p className="text-xl mb-2">Date of establishment</p>
          <input
            disabled={true}
            className={`w-full mb-1 px-4 py-3 rounded-md bg-gray-100 ${
              errors.dateOfEstablishment
                ? "text-red-400 outline outline-2 outline-red-400"
                : "text-primary"
            } disabled:bg-gray-200`}
            type="date"
            // value={formatDate(getValues("center.dateOfEstablishment"))}
            {...register("userCenter.center.dateOfEstablishment", {
              valueAsDate: true,
              required: true,
            })}
          />
          {errors.dateOfEstablishment && (
            <p className="text-red-400">Provide a date</p>
          )}
        </div>
        <div className="flex-1">
          <p className="text-xl mb-2">Name of the Centre</p>
          <input
            disabled={true}
            className={`w-full mb-1 px-4 py-3 rounded-md bg-gray-100 ${
              errors.name
                ? "text-red-400 outline outline-2 outline-red-400"
                : "text-primary"
            } disabled:bg-gray-200`}
            placeholder="Center for Sustainable Energy"
            type="text"
            // value={getValues("center.name")}
            {...register("userCenter.center.name", { required: true })}
          />
          {errors.name && <p className="text-red-400">Provide a valid text</p>}
        </div>
        <div className="flex-1">
          <p className="text-xl mb-2">Purpose of the Centre</p>
          <input
            disabled={true}
            className={`w-full mb-1 px-4 py-3 rounded-md bg-gray-100 ${
              errors.purposeOfCentre
                ? "text-red-400 outline outline-2 outline-red-400"
                : "text-primary"
            } disabled:bg-gray-200`}
            placeholder="To research and promote sustainable energy"
            type="text"
            // value={getValues("center.purposeOfCenter")}
            {...register("userCenter.center.purposeOfCenter", {
              required: true,
            })}
          />
          {errors.purposeOfCentre && (
            <p className="text-red-400">Provide a valid text</p>
          )}
        </div>
      </div>
      <div className="flex flex-row space-x-5 mb-8">
        <div className="flex-1">
          <p className="text-xl mb-2">Centre Incharge</p>
          <input
            disabled={true}
            className={`w-full px-4 py-3 rounded-md bg-gray-100 ${
              errors.inCharge
                ? "text-red-400 outline outline-2 outline-red-400"
                : "text-primary"
            } disabled:bg-gray-200`}
            placeholder="20pt25"
            type="text"
            // value={getValues("center.userEmail")}
            {...register("userCenter.user.email", { required: true })}
          />
          {errors.inCharge && (
            <p className="text-red-400">Provide a valid user</p>
          )}
        </div>
        <div className="flex-1">
          <p className="text-xl mb-2">Funded by</p>
          <input
            disabled={true}
            className={`w-full px-4 py-3 rounded-md bg-gray-100 ${
              errors.fundedBy
                ? "text-red-400 outline outline-2 outline-red-400"
                : "text-primary"
            } disabled:bg-gray-200`}
            placeholder="Management/DST/AICTE/Others (specify)"
            type="text"
            // value={getValues("center.fundedBy")}
            {...register("userCenter.center.fundedBy", { required: true })}
          />
          {errors.fundedBy && (
            <p className="text-red-400">Provide a valid text</p>
          )}
        </div>
      </div>
      <div className="mb-8">
        <p className="text-xl mb-2">List of Hardware and Software</p>
        <div className="mb-4">
          <p className="w-full px-4 py-2 bg-gray-300 text-center border border-black">
            Hardware
          </p>
          <div className="w-full flex flex-row bg-gray-300 text-center border border-t-0 border-black">
            <p className="px-4 py-2 w-20">S No.</p>
            <p className="px-4 py-2 flex-1 border-l border-black">
              Description
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black">Quantity</p>
          </div>
          {hardwareList.map((field, index) => (
            <div
              key={field.id}
              className={`group w-full flex flex-row items-center ${
                index % 2 ? "bg-gray-200" : "bg-gray-100"
              } text-center border border-t-0 border-black`}
            >
              <div className="px-4 py-2 w-20 grid place-items-center">
                {isShow ? (
                  <p>{index + 1}</p>
                ) : (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        hardwareListRemove(index);
                      }}
                      className="hidden group-hover:block hover:text-red-500"
                    >
                      <AiFillDelete />
                    </button>
                    <p className="group-hover:hidden">{index + 1}</p>
                  </>
                )}
              </div>
              <div className="px-4 py-2 flex-1 border-l border-black">
                <input
                  disabled={isShow}
                  type="text"
                  className={` w-full text-center bg-transparent ${
                    errors?.hardwareList?.[index]?.description &&
                    "outline outline-2 outline-red-400"
                  }`}
                  {...register(`hardwareList.${index}.description`)}
                />
              </div>
              <div className="px-4 py-2 flex-1 border-l border-black">
                <input
                  disabled={isShow}
                  type="number"
                  className={`w-full text-center bg-transparent ${
                    errors?.hardwareList?.[index]?.quantity &&
                    "outline outline-2 outline-red-400"
                  }`}
                  min={0}
                  {...register(`hardwareList.${index}.quantity`)}
                />
              </div>
            </div>
          ))}
          {!isShow && (
            <div className="w-full flex flex-row bg-gray-100 text-center border border-t-0 border-black">
              <div className="px-4 py-2 w-20 grid place-items-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    hardwareListAppend({ description: "", quantity: "0" });
                  }}
                >
                  <GrAdd />
                </button>
              </div>
              <p className="px-4 py-2 flex-1 border-l border-black"></p>
              <p className="px-4 py-2 flex-1 border-l border-black"></p>
            </div>
          )}
        </div>
        <div>
          <p className="w-full px-4 py-2 bg-gray-300 text-center border border-black">
            Software
          </p>
          <div className="w-full flex flex-row bg-gray-300 text-center border border-t-0 border-black">
            <p className="px-4 py-2 w-20">S No.</p>
            <p className="px-4 py-2 flex-1 border-l border-black">
              Description
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black">
              Version License
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black">Quantity</p>
          </div>
          {softwareList.map((field, index) => (
            <div
              key={field.id}
              className={`group w-full flex flex-row items-center ${
                index % 2 ? "bg-gray-200" : "bg-gray-100"
              } text-center border border-t-0 border-black`}
            >
              <div className="px-4 py-2 w-20 grid place-items-center">
                {isShow ? (
                  <p>{index + 1}</p>
                ) : (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        softwareListRemove(index);
                      }}
                      className="hidden group-hover:block hover:text-red-500"
                    >
                      <AiFillDelete />
                    </button>
                    <p className="group-hover:hidden">{index + 1}</p>
                  </>
                )}
              </div>
              <div className="px-4 py-2 flex-1 border-l border-black">
                <input
                  disabled={isShow}
                  type="text"
                  className={`w-full text-center bg-transparent ${
                    errors?.softwareList?.[index]?.description &&
                    "outline outline-2 outline-red-400"
                  }`}
                  {...register(`softwareList.${index}.description`)}
                />
              </div>
              <div className="px-4 py-2 flex-1 border-l border-black">
                <input
                  disabled={isShow}
                  type="text"
                  className={`w-full text-center bg-transparent ${
                    errors?.softwareList?.[index]?.version &&
                    "outline outline-2 outline-red-400"
                  }`}
                  {...register(`softwareList.${index}.version`)}
                />
              </div>
              <div className="px-4 py-2 flex-1 border-l border-black">
                <input
                  type="number"
                  className={`w-full text-center bg-transparent ${
                    errors?.softwareList?.[index]?.quantity &&
                    "outline outline-2 outline-red-400"
                  }`}
                  min={0}
                  {...register(`softwareList.${index}.quantity`)}
                />
              </div>
            </div>
          ))}
          {!isShow && (
            <div className="w-full flex flex-row bg-gray-100 text-center border border-t-0 border-black">
              <div className="px-4 py-2 w-20 grid place-items-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    softwareListAppend({ description: "", quantity: "0" });
                  }}
                >
                  <GrAdd />
                </button>
              </div>
              <p className="px-4 py-2 flex-1 border-l border-black"></p>
              <p className="px-4 py-2 flex-1 border-l border-black"></p>
              <p className="px-4 py-2 flex-1 border-l border-black"></p>
            </div>
          )}
        </div>
      </div>
      <div className="mb-8">
        <p className="text-xl mb-2">Other features</p>
        <RichTextEditor isShow={isShow} name="otherFeatures" />
      </div>
      <div className="mb-8">
        <p className="text-xl mb-2">Technical Staff Details</p>
        <div>
          <div className="w-full flex flex-row bg-gray-300 text-center border border-black">
            <p className="px-4 py-2 w-20">S No.</p>
            <p className="px-4 py-2 flex-1 border-l border-black">Name</p>
            <p className="px-4 py-2 flex-1 border-l border-black">
              Designation
            </p>
          </div>
          {technicalStaffDetails.map((field, index) => (
            <div
              key={field.id}
              className={`group w-full flex flex-row items-center ${
                index % 2 ? "bg-gray-200" : "bg-gray-100"
              } text-center border border-t-0 border-black`}
            >
              <div className="px-4 py-2 w-20 grid place-items-center">
                {isShow ? (
                  <p>{index + 1}</p>
                ) : (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        technicalStaffDetailsRemove(index);
                      }}
                      className="hidden group-hover:block hover:text-red-500"
                    >
                      <AiFillDelete />
                    </button>
                    <p className="group-hover:hidden">{index + 1}</p>
                  </>
                )}
              </div>
              <div className="px-4 py-2 flex-1 border-l border-black">
                <input
                  disabled={isShow}
                  type="text"
                  className={`w-full text-center bg-transparent ${
                    errors?.technicalStaffDetails?.[index]?.name &&
                    "outline outline-2 outline-red-400"
                  }`}
                  {...register(`technicalStaffDetails.${index}.name`)}
                />
              </div>
              <div className="px-4 py-2 flex-1 border-l border-black">
                <input
                  disabled={isShow}
                  type="text"
                  className={`w-full text-center bg-transparent ${
                    errors?.technicalStaffDetails?.[index]?.designation &&
                    "outline outline-2 outline-red-400"
                  }`}
                  {...register(`technicalStaffDetails.${index}.designation`)}
                />
              </div>
            </div>
          ))}
          {!isShow && (
            <div className="w-full flex flex-row bg-gray-100 text-center border border-t-0 border-black">
              <div className="px-4 py-2 w-20 grid place-items-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    technicalStaffDetailsAppend({ name: "", designation: "" });
                  }}
                >
                  <GrAdd />
                </button>
              </div>
              <p className="px-4 py-2 flex-1 border-l border-black"></p>
              <p className="px-4 py-2 flex-1 border-l border-black"></p>
            </div>
          )}
        </div>
      </div>
      <div className="mb-8">
        <p className="text-xl mb-2">Laboratory Sessions Hours</p>
        <div>
          <div className="w-full flex flex-row items-center bg-gray-300 text-center border border-black">
            <p className="px-4 py-2 flex-1 break-all">Hours/Days</p>
            <p className="px-4 py-2 flex-1 border-l border-black break-all">
              1<br />
              (08:30 - 09:20)
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black break-all">
              2<br />
              (09:20 - 10:10)
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black break-all">
              3<br />
              (10:30 - 11:20)
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black break-all">
              4<br />
              (11:20 - 12:10)
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black break-all">
              5<br />
              (01:40 - 12:10)
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black break-all">
              6<br />
              (02:30 - 03:20)
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black break-all">
              7<br />
              (03:30 - 04:20)
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black break-all">
              8<br />
              (04:20 - 05:10)
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black break-all">
              9<br />
              (05:30 - 06:10)
            </p>
          </div>
          {studentSessions.map((field, index) => (
            <div
              key={field.id}
              className={`w-full flex flex-row ${
                index % 2 ? "bg-gray-200" : "bg-gray-100"
              } text-center border border-t-0 border-black`}
            >
              <p className="px-4 py-2 flex-1 break-all">{weekDays[index]}</p>
              {Array.from({ length: 9 }, (_, index) => index + 1).map((v) => (
                <div key={v} className="px-4 py-2 flex-1 border-l border-black">
                  <input
                    disabled={isShow}
                    type="number"
                    className={`w-full text-center bg-transparent ${
                      errors?.studentSessions?.[index]?.[v] &&
                      "outline outline-2 outline-red-400"
                    }`}
                    {...register(`studentSessions.${index}.${v}`, {
                      valueAsNumber: true,
                    })}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="mb-8">
        <p className="text-xl mb-2">Research Activities (PG/PhD)</p>
        <div>
          <div className="w-full flex flex-row bg-gray-300 text-center border border-black">
            <p className="px-4 py-2 w-20">S No.</p>
            <p className="px-4 py-2 flex-1 border-l border-black">
              Name of the Candidate
            </p>
            <p className="px-4 py-2 flex-1 border-l border-black">Supervisor</p>
          </div>
          {researchActivities.map((field, index) => (
            <div
              key={field.id}
              className={`group w-full flex flex-row items-center ${
                index % 2 ? "bg-gray-200" : "bg-gray-100"
              } text-center border border-t-0 border-black`}
            >
              <div className="px-4 py-2 w-20 grid place-items-center">
                {isShow ? (
                  <p>{index + 1}</p>
                ) : (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        researchActivitiesRemove(index);
                      }}
                      className="hidden group-hover:block hover:text-red-500"
                    >
                      <AiFillDelete />
                    </button>
                    <p className="group-hover:hidden">{index + 1}</p>
                  </>
                )}
              </div>
              <div className="px-4 py-2 flex-1 border-l border-black">
                <input
                  disabled={isShow}
                  type="text"
                  className={`w-full text-center bg-transparent ${
                    errors?.researchActivities?.[index]?.name &&
                    "outline outline-2 outline-red-400"
                  }`}
                  {...register(`researchActivities.${index}.name`)}
                />
              </div>
              <div className="px-4 py-2 flex-1 border-l border-black">
                <input
                  disabled={isShow}
                  type="text"
                  className={`w-full text-center bg-transparent ${
                    errors?.researchActivities?.[index]?.designation &&
                    "outline outline-2 outline-red-400"
                  }`}
                  {...register(`researchActivities.${index}.supervisor`)}
                />
              </div>
            </div>
          ))}
          {!isShow && (
            <div className="w-full flex flex-row bg-gray-100 text-center border border-t-0 border-black">
              <div className="px-4 py-2 w-20 grid place-items-center">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    researchActivitiesAppend({ name: "", supervisor: "" });
                  }}
                >
                  <GrAdd />
                </button>
              </div>
              <p className="px-4 py-2 flex-1 border-l border-black"></p>
              <p className="px-4 py-2 flex-1 border-l border-black"></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const CentreFundRaisingDetails = ({ className, isShow }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const {
    fields: fundRaisingActivities,
    append: fundRaisingActivitiesAppend,
    remove: fundRaisingActivitiesRemove,
  } = useFieldArray({ name: "fundRaisingActivities" });

  return (
    <div className={className}>
      <div className="mb-8">
        <p className="text-xl mb-2">Fund Raising Activities</p>
        <div className="w-full flex flex-row bg-gray-300 text-center border items-center border-black">
          <p className="px-4 py-2 w-20">S No.</p>
          <p className="px-4 py-2 flex-1 border-l border-black">Client</p>
          <p className="px-4 py-2 flex-1 border-l border-black">
            Nature of Work
          </p>
          <p className="px-4 py-2 flex-1 border-l border-black">
            Revenue Generated (inclusive of tax) in INR
          </p>
        </div>
        {fundRaisingActivities.map((field, index) => (
          <div
            key={field.id}
            className={`group w-full flex flex-row items-center ${
              index % 2 ? "bg-gray-200" : "bg-gray-100"
            } text-center border border-t-0 border-black`}
          >
            <div className="px-4 py-2 w-20 grid place-items-center">
              {isShow ? (
                <p>{index + 1}</p>
              ) : (
                <>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      fundRaisingActivitiesRemove(index);
                    }}
                    className="hidden group-hover:block hover:text-red-500"
                  >
                    <AiFillDelete />
                  </button>
                  <p className="group-hover:hidden">{index + 1}</p>
                </>
              )}
            </div>
            <div className="px-4 py-2 flex-1 border-l border-black">
              <input
                disabled={isShow}
                type="text"
                className={`w-full text-center bg-transparent ${
                  errors?.fundRaisingActivities?.[index]?.client &&
                  "outline outline-2 outline-red-400"
                }`}
                {...register(`fundRaisingActivities.${index}.client`)}
              />
            </div>
            <div className="px-4 py-2 flex-1 border-l border-black">
              <input
                disabled={isShow}
                type="text"
                className={`w-full text-center bg-transparent ${
                  errors?.fundRaisingActivities?.[index]?.natureOfWork &&
                  "outline outline-2 outline-red-400"
                }`}
                {...register(`fundRaisingActivities.${index}.natureOfWork`)}
              />
            </div>
            <div className="px-4 py-2 flex-1 border-l border-black">
              <input
                disabled={isShow}
                type="text"
                className={`w-full text-center bg-transparent ${
                  errors?.fundRaisingActivities?.[index]?.revenue &&
                  "outline outline-2 outline-red-400"
                }`}
                {...register(`fundRaisingActivities.${index}.revenue`, {})}
              />
            </div>
          </div>
        ))}
        {!isShow && (
          <div className="w-full flex flex-row bg-gray-100 text-center border border-t-0 border-black">
            <div className="px-4 py-2 w-20 grid place-items-center">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  fundRaisingActivitiesAppend({
                    client: "",
                    natureOfWork: "",
                    revenue: "",
                  });
                }}
              >
                <GrAdd />
              </button>
            </div>
            <p className="px-4 py-2 flex-1 border-l border-black"></p>
            <p className="px-4 py-2 flex-1 border-l border-black"></p>
            <p className="px-4 py-2 flex-1 border-l border-black"></p>
          </div>
        )}
      </div>
    </div>
  );
};

export const CentreWorkshopDetails = ({ className, isShow }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={className}>
      <div className="mb-8">
        <div className="mb-4">
          <p className="text-xl mb-2">Total number of courses conducted </p>
          <input
            disabled={isShow}
            className={`w-full mb-1 px-4 py-3 rounded-md bg-gray-100 ${
              errors?.coursesAndWorkshop?.courses
                ? "text-red-400 outline outline-2 outline-red-400"
                : "text-primary"
            } disabled:bg-gray-200`}
            type="number"
            min={0}
            {...register("coursesAndWorkshop.courses")}
          />
          {errors?.coursesAndWorkshop?.courses && (
            <p className="text-red-400">Provide a valid number</p>
          )}
        </div>
        <div className="mb-4">
          <p className="text-xl mb-2">Total number of participants</p>
          <input
            disabled={isShow}
            className={`w-full mb-1 px-4 py-3 rounded-md bg-gray-100 ${
              errors?.coursesAndWorkshop?.participants
                ? "text-red-400 outline outline-2 outline-red-400"
                : "text-primary"
            } disabled:bg-gray-200`}
            type="number"
            min={0}
            {...register("coursesAndWorkshop.participants")}
          />
          {errors?.coursesAndWorkshop?.participants && (
            <p className="text-red-400">Provide a valid number</p>
          )}
        </div>
        <div className="mb-4">
          <p className="text-xl mb-2">Revenue generated</p>
          <input
            disabled={isShow}
            className={`w-full mb-1 px-4 py-3 rounded-md bg-gray-100 ${
              errors?.coursesAndWorkshop?.revenue
                ? "text-red-400 outline outline-2 outline-red-400"
                : "text-primary"
            } disabled:bg-gray-200`}
            type="number"
            min={0}
            {...register("coursesAndWorkshop.revenue")}
          />
          {errors?.coursesAndWorkshop?.revenue && (
            <p className="text-red-400">Provide a valid number</p>
          )}
        </div>
      </div>
    </div>
  );
};
