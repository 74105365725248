import HeaderImage from "../assests/headerImage.png";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

const Login = () => {
  const { login, fetchMe } = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const handleFormSubmit = async (data) => {
    try {
      await login(data);
    } catch (err) {
      const response = err?.response;
      const data = response?.data;
      if (data) {
        for (const key in data) {
          if (key === "_errors") {
            if (data[key].length > 0) {
              setError("root", {
                type: response.status,
                message: data[key].join("\n"),
              });
            }
          } else {
            if (data[key]["_errors"].length > 0) {
              setError(key, {
                type: "server",
                message: data[key]["_errors"].join("\n"),
              });
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      await fetchMe();
    })();
  }, []);

  return (
    <div className="flex flex-row flex-1 px-5">
      <div className="flex-1 flex flex-row justify-center items-center">
        <div className="text-primary">
          <div className="text-center mb-16">
            <h1 className="font-bold text-5xl md:text-7xl mb-4 font-ws">
              Centre of Excellence
            </h1>
            <h4 className="text-2xl md:text-4xl font-ws">
              Centre Incharge Login
            </h4>
          </div>
          <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="w-full mb-16">
              <div className="mb-8">
                <p className="text-xl md:text-2xl font-bold mb-4">
                  Email Address
                </p>
                <input
                  className={`w-full mb-1 px-4 py-3 rounded-md bg-gray-100 ${
                    errors.email
                      ? "text-red-400 outline outline-2 outline-red-400"
                      : "text-primary"
                  }`}
                  placeholder="someone@psgtech.ac.in"
                  type="email"
                  {...register("email", { required: true })}
                />
                {errors?.email && (
                  <p className="text-red-400">{errors?.email?.message}</p>
                )}
              </div>
              <div className="mb-2">
                <p className="text-xl md:text-2xl font-bold mb-4">Password</p>
                <div>
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                      className="absolute right-[.5rem] top-[50%] translate-y-[-50%] text-2xl"
                    >
                      {!showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </button>
                    <input
                      className={`w-full mb-1 pl-4 pr-6 py-3 rounded-md bg-gray-100 ${
                        errors.password
                          ? "text-red-400 outline outline-2 outline-red-400"
                          : "text-primary"
                      }`}
                      placeholder="something secret"
                      type={showPassword ? "text" : "password"}
                      {...register("password", {
                        required: true,
                      })}
                    />
                  </div>
                  {errors?.password && (
                    <p className="text-red-400">{errors?.password?.message}</p>
                  )}
                </div>
              </div>
              {errors?.root && (
                <p className="text-red-400">{errors?.root?.message}</p>
              )}
              <div className="text-right">
                <Link to="/forgot-password" className="underline">Forgot Password?</Link>
              </div>
            </div>
            <div className="text-center">
              <input
                type="submit"
                className="px-4 text-lg py-2 bg-primary text-white rounded-lg cursor-pointer"
                value="Login"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="flex-1 hidden lg:block">
        <img src={HeaderImage} alt="Header" />
      </div>
    </div>
  );
};

export default Login;
