import { useState } from "react";
import { toast } from "react-hot-toast";
import { AiFillCloseCircle } from "react-icons/ai";

const VerifyReportToast = ({ t, onSubmit }) => {
  const [verifiedCheck, setVerifiedCheck] = useState(false);

  return (
    <div className="flex space-x-4">
      <button
        className="text-right"
        onClick={(e) => {
          e.preventDefault();
          toast.dismiss(t.id);
        }}
      >
        <AiFillCloseCircle className="text-red-500 text-xl" />
      </button>
      <div>
        <div className="mb-4">
          <p className="mb-4">
            You will not be able to edit after submitting.{" "}
            <span className="font-bold">
              If you still want to completed the report later, kindly update the
              report
            </span>
          </p>
          <div class="flex items-center">
            <input
              checked={verifiedCheck}
              id="checked-checkbox"
              type="checkbox"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={(e) => {
                setVerifiedCheck(!verifiedCheck);
              }}
            />
            <label
              for="checked-checkbox"
              class="ml-2 text-sm font-medium text-gray-900"
            >
              Verified and Approved
            </label>
          </div>
        </div>
        <button
          disabled={!verifiedCheck}
          className="bg-green-500 px-4 py-2 rounded-lg text-white disabled:opacity-70"
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
            toast.dismiss(t.id);
          }}
        >
          Confirm Submit
        </button>
      </div>
    </div>
  );
};

export default VerifyReportToast;
