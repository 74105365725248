import { Navigate, Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar";
import OuterWrapper from "../components/OuterWrapper";
import Sidebar from "../components/user/Sidebar";
import CenterHome from "./user/CenterHome";
import Login from "./Login";
import Reports from "./user/Reports";
import ReportShow from "./user/ReportShow";
import ReportEdit from "./user/ReportEdit";
import AdminSidebar from "../components/admin/AdminSidebar";
import AddUser from "./admin/AddUser";
import AddCentre from "./admin/AddCentre";
import AuthProvider from "../context/AuthContext";
import Logout from "./Logout";
import ForgotPassword from "./ForgotPassword";
import RequireAuth from "../components/RequireAuth";
import Home from "./user/Home";
import { Toaster } from "react-hot-toast";
import AdminCenter from "./admin/Center";
import EditCentre from "./admin/EditCentre";
import ShowCentre from "./admin/ShowCentre";
import SimpleSidebar from "../components/user/SimpleSidebar";
import AdminReports from "./admin/Reports";
import AdminReportShow from "./admin/ReportShow";
import GenerateReports from "./admin/GenerateReports";
import ChangePassword from "./ChangePassword";
import { ADMIN_CONSOLE_URL } from "../api/endpoints";

const App = () => {
  return (
    <AuthProvider>
      <Toaster />
      <Routes>
        <Route path="/" element={<OuterWrapper />}>
          <Route index element={<Navigate to="/login" replace />} />
          <Route element={<Navbar />}>
            {/* User Routes */}
            <Route element={<RequireAuth allowedRoles={["USER", "ADMIN"]} />}>
              <Route element={<SimpleSidebar />}>
                <Route path="center" element={<Home />} />
              </Route>
              <Route element={<Sidebar />}>
                <Route path="center/:centerId/home" element={<CenterHome />} />
                <Route path="center/:centerId/reports" element={<Reports />} />
                <Route
                  path="center/:centerId/reports/:id"
                  element={<ReportShow />}
                />
                <Route
                  path="center/:centerId/reports/:id/edit"
                  element={<ReportEdit />}
                />
              </Route>
            </Route>
            {/* Admin Routes */}
            <Route element={<RequireAuth allowedRoles={["ADMIN"]} />}>
              <Route
                path="/admin/*"
                Component={() => {
                  window.location.href = ADMIN_CONSOLE_URL;
                  return null;
                }}
              >
                {/* <Route index element={<Navigate to="/admin/user" replace />} />
                <Route path="/admin/user" element={<AddUser />} />
                <Route path="/admin/centre" element={<AdminCenter />} />
                <Route path="/admin/centre/add" element={<AddCentre />} />
                <Route
                  path="/admin/centre/:centerId"
                  element={<ShowCentre />}
                />
                <Route
                  path="/admin/centre/:centerId/edit"
                  element={<EditCentre />}
                />
                <Route path="/admin/reports" element={<AdminReports />} />
                <Route
                  path="/admin/reports/:id"
                  element={<AdminReportShow />}
                />
                <Route
                  path="/admin/reports/generate"
                  element={<GenerateReports />}
                /> */}
              </Route>
            </Route>
          </Route>
          {/* Public Routes */}
          <Route path="/login" element={<Navbar />}>
            <Route index element={<Login />} />
          </Route>
          <Route path="/logout" element={<Navbar />}>
            <Route index element={<Logout />} />
          </Route>
          <Route path="/forgot-password" element={<Navbar />}>
            <Route index element={<ForgotPassword />} />
          </Route>
          <Route path="/change-password/:token" element={<Navbar />}>
            <Route index element={<ChangePassword />} />
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default App;
