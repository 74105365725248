import { useEffect, useState } from "react";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import {
  CentreDetails,
  CentreFundRaisingDetails,
  CentreWorkshopDetails,
} from "../../components/user/ReportForm";
import { FormProvider, useForm } from "react-hook-form";
import ScrollIntoView from "../../components/ScrollIntoView";
import { formatDate } from "../../utils";
import axiosInstance from "../../api";
import { REPORT_ONE } from "../../api/endpoints";
import { useParams } from "react-router-dom";

const ReportShow = () => {
  const { id } = useParams();

  const [values, setValues] = useState({});
  const [status, setStatus] = useState("PENDING");

  const methods = useForm({
    values: values,
  });

  const [step, setStep] = useState(0);

  const updateReport = async (body) => {
    try {
      const result = await axiosInstance.put(
        REPORT_ONE.replace(":id", id),
        body
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchReport = async () => {
    try {
      const result = await axiosInstance.get(REPORT_ONE.replace(":id", id));
      let data = result.data;

      if (data && data.status !== "COMPLETED") {
        const today = new Date();
        const deadline = new Date(data.deadline);
        if (today.getTime() > deadline.getTime()) {
          setStatus("DEADLINE");
        } else {
          setStatus("PENDING");
        }
      } else {
        setStatus("COMPLETED");
      }

      setValues({
        ...data,
        userCenter: {
          ...data.userCenter,
          center: {
            ...data.userCenter.center,
            dateOfEstablishment: formatDate(
              data.userCenter.center.dateOfEstablishment
            ),
          },
        },
        studentSessions: data.studentSessions || [
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
        ],
        coursesAndWorkshop: data.coursesAndWorkshop || {
          courses: "0",
          participants: "0",
          revenue: "0",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  useEffect(() => {
    console.log(values);
  }, [values]);

  return (
    <div className="p-10 h-full flex flex-col space-y-10">
      <div className="flex flex-row justify-center">
        <div className="flex-1 text-xl">
          <p>Quarter : {values?.quarter}</p>
          <p>Year : {values?.academicYear}</p>
        </div>
        <div className="flex flex-row items-center flex-1 text-white">
          <button
            onClick={(e) => {
              if (step >= 1) {
                setStep(step - 1);
              }
            }}
            className="text-primary mr-4 text-2xl"
          >
            <IoIosArrowDropleftCircle />
          </button>
          <p
            className={`rounded-full p-2 aspect-square w-10 text-center ${
              step >= 0 ? "bg-primary text-white" : "text-black"
            }`}
          >
            1
          </p>
          <div
            className={`flex-1 h-1 ${step >= 1 ? "bg-primary" : "bg-gray-300"}`}
          ></div>
          <p
            className={`rounded-full p-2 aspect-square w-10 text-center ${
              step >= 1 ? "bg-primary text-white" : "text-black bg-gray-300"
            }`}
          >
            2
          </p>
          <div
            className={`flex-1 h-1 ${step >= 2 ? "bg-primary" : "bg-gray-300"}`}
          ></div>
          <p
            className={`rounded-full p-2 aspect-square w-10 text-center ${
              step >= 2 ? "bg-primary text-white" : "text-black bg-gray-300"
            }`}
          >
            3
          </p>
          <button
            onClick={(e) => {
              if (step <= 1) {
                setStep(step + 1);
              }
            }}
            className="text-primary ml-4 text-2xl"
          >
            <IoIosArrowDroprightCircle />
          </button>
        </div>
        <div className="flex-1 text-right"></div>
      </div>
      <FormProvider {...methods}>
        <form className="flex-1 overflow-y-auto px-2">
          <ScrollIntoView condition={step === 0}>
            <CentreDetails isShow={true} />
          </ScrollIntoView>
          <ScrollIntoView condition={step === 1}>
            <CentreFundRaisingDetails isShow={true} />
          </ScrollIntoView>
          <ScrollIntoView condition={step === 2}>
            <CentreWorkshopDetails isShow={true} />
          </ScrollIntoView>
        </form>
      </FormProvider>
    </div>
  );
};

export default ReportShow;
