import { useEffect, useState } from "react";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import {
  CentreDetails,
  CentreFundRaisingDetails,
  CentreWorkshopDetails,
} from "../../components/user/ReportForm";
import { FormProvider, useForm } from "react-hook-form";
import ScrollIntoView from "../../components/ScrollIntoView";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../api";
import { REPORT_ONE, REPORT_UPDATE_STATUS } from "../../api/endpoints";
import { formatDate, removeEmpty } from "../../utils";
import { BiTimeFive } from "react-icons/bi";
import { toast } from "react-hot-toast";
import { AiFillCloseCircle, AiFillQuestionCircle } from "react-icons/ai";
import VerifyReportToast from "../../components/user/verifyReportToast";

const ReportEdit = () => {
  const { id, centerId } = useParams();
  const navigate = useNavigate();

  const [values, setValues] = useState({});
  const [status, setStatus] = useState("PENDING");

  const methods = useForm({
    values: values,
  });

  const { setError } = methods;

  const [step, setStep] = useState(0);

  const updateReport = async (body) => {
    try {
      const result = await axiosInstance.put(REPORT_ONE.replace(":id", id), {
        data: body,
      });
      toast.success("Updated Report, continue editing...");
    } catch (err) {
      const response = err?.response;
      const data = response?.data;
      if (data) {
        for (const key in data) {
          if (key === "_errors") {
            if (data[key].length > 0) {
              setError("root", {
                type: response.status,
                message: data[key].join("\n"),
              });
            }
          } else {
            if (data[key]["_errors"].length > 0) {
              setError(key, {
                type: "server",
                message: data[key]["_errors"].join("\n"),
              });
            }
          }
        }
      }
    }
  };

  const updateReportStatus = async (body) => {
    try {
      const result = await axiosInstance.put(
        REPORT_UPDATE_STATUS.replace(":id", id),
        {
          data: body,
        }
      );
      toast.success("Successfully Submitted Report.");
    } catch (err) {}
  };

  const onToastSubmit = () => {
    updateReportStatus({ status: "COMPLETED" });
    navigate(`/center/${centerId}/home`);
  };

  const confirmSubmit = () => {
    toast.custom();
    toast((t) => <VerifyReportToast t={t} onSubmit={onToastSubmit} />, {
      duration: 1000000,
    });
  };

  const fetchReport = async () => {
    try {
      const result = await axiosInstance.get(REPORT_ONE.replace(":id", id));
      let data = result.data;

      if (data && data.status !== "COMPLETED") {
        const today = new Date();
        const deadline = new Date(data.deadline);
        if (today.getTime() > deadline.getTime()) {
          setStatus("DEADLINE");
        } else {
          setStatus("PENDING");
        }
      } else {
        setStatus("COMPLETED");
      }

      setValues({
        ...data,
        userCenter: {
          ...data.userCenter,
          center: {
            ...data.userCenter.center,
            dateOfEstablishment: formatDate(
              data.userCenter.center.dateOfEstablishment
            ),
          },
        },
        studentSessions: data.studentSessions || [
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
          { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 },
        ],
        coursesAndWorkshop: data.coursesAndWorkshop || {
          courses: "0",
          participants: "0",
          revenue: "0",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <div className="p-10 h-full flex flex-col space-y-10">
      <div className="flex flex-row justify-center items-center">
        <div className="flex-1 text-xl">
          <p>Quarter : {values?.quarter}</p>
          <p>Year : {values?.academicYear}</p>
        </div>
        <div className="flex flex-row items-center flex-1 text-white">
          <button
            onClick={(e) => {
              if (step >= 1) {
                setStep(step - 1);
              }
            }}
            className="text-primary mr-4 text-2xl"
          >
            <IoIosArrowDropleftCircle />
          </button>
          <p
            className={`rounded-full p-2 aspect-square w-10 text-center ${
              step >= 0 ? "bg-primary text-white" : "text-black"
            }`}
          >
            1
          </p>
          <div
            className={`flex-1 h-1 ${step >= 1 ? "bg-primary" : "bg-gray-300"}`}
          ></div>
          <p
            className={`rounded-full p-2 aspect-square w-10 text-center ${
              step >= 1 ? "bg-primary text-white" : "text-black bg-gray-300"
            }`}
          >
            2
          </p>
          <div
            className={`flex-1 h-1 ${step >= 2 ? "bg-primary" : "bg-gray-300"}`}
          ></div>
          <p
            className={`rounded-full p-2 aspect-square w-10 text-center ${
              step >= 2 ? "bg-primary text-white" : "text-black bg-gray-300"
            }`}
          >
            3
          </p>
          <button
            onClick={(e) => {
              if (step <= 1) {
                setStep(step + 1);
              }
            }}
            className="text-primary ml-4 text-2xl"
          >
            <IoIosArrowDroprightCircle />
          </button>
        </div>
        <div className="flex-1 text-right">
          {status === "PENDING" && (
            <>
              <input
                className="cursor-pointer px-4 py-2 bg-primary text-white rounded-lg"
                type="submit"
                value="Update"
                form="reportForm"
              />
              <input
                onClick={(e) => {
                  confirmSubmit();
                }}
                className="cursor-pointer px-4 py-2 bg-gray-500 text-white rounded-lg ml-4"
                type="submit"
                value="Submit"
              />
            </>
          )}
          {status === "DEADLINE" && (
            <button className="bg-red-600 text-white text-xl px-4 py-3 rounded-lg">
              Request Extension <BiTimeFive className="inline text-2xl ml-2" />
            </button>
          )}
        </div>
      </div>
      <FormProvider {...methods}>
        <form
          id="reportForm"
          className="flex-1 overflow-y-auto px-2"
          onSubmit={methods.handleSubmit(updateReport)}
        >
          <ScrollIntoView condition={step === 0}>
            <CentreDetails isShow={false} />
          </ScrollIntoView>
          <ScrollIntoView condition={step === 1}>
            <CentreFundRaisingDetails isShow={false} />
          </ScrollIntoView>
          <ScrollIntoView condition={step === 2}>
            <CentreWorkshopDetails isShow={false} />
          </ScrollIntoView>
        </form>
      </FormProvider>
    </div>
  );
};

export default ReportEdit;
