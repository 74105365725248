// export const BASE_URL = "http://localhost:8080";
export const BASE_URL = "";
export const API_URL = BASE_URL + "/api";

export const AUTH_BASE = API_URL + "/auth";
export const AUTH_LOGIN = AUTH_BASE + "/login";
export const AUTH_FORGOT_PASSWORD = AUTH_BASE + "/forgot-password";
export const AUTH_CHANGE_PASSWORD = AUTH_BASE + "/change-password/:token";

export const USER_BASE = API_URL + "/user";
export const USER_ME = USER_BASE + "/me";

export const CENTER_BASE = API_URL + "/center";
export const CENTER_GET = CENTER_BASE + "/";
export const CENTER_GET_ONE = CENTER_BASE + "/:id";
export const CENTER_ADD_USERS = CENTER_BASE + "/:id/add-users";
export const CENTER_REMOVE_USERS = CENTER_BASE + "/:id/delete-users";
export const CENTER_REPORT_LIST = CENTER_BASE + "/:id/reports";
export const CENTER_REPORT_CHECK = CENTER_BASE + "/:id/check";

export const REPORT_BASE = API_URL + "/report";
export const REPORT_ONE = REPORT_BASE + "/:id";
export const REPORT_UPDATE_STATUS = REPORT_BASE + "/:id/update-status";

export const MISC_BASE = API_URL + "/misc";
export const MISC_GEN_REPORTS = MISC_BASE + "/generateReports";

export const ADMIN_CONSOLE_URL = "https://coe-admin.web.app"